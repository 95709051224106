import styled from '@emotion/styled'
import {padding, margin} from 'styles'

const Wrapper = styled.div([
  {
    ...padding.x(24),
    ...margin.x('auto'),
  },
  ({narrow}: {narrow?: boolean}) => ({
    maxWidth: narrow ? 650 : 1200,
  }),
])

export default Wrapper
