import React from 'react'
import {css} from '@emotion/core'
import styled from '@emotion/styled'
import {graphql} from 'gatsby'
import SiteMetadata from 'components/SiteMetadata'
import Wrapper from 'components/Wrapper'
import {padding, typography, margin, colors} from 'styles'
import {LegalPageQuery} from 'landing-gatsby-graphql-generated'
import invariant from 'tiny-invariant'

interface Props {
  data: LegalPageQuery
}

const Heading = styled.h1({
  ...typography.headline1,
  ...margin.bottom(36),
})

const markdownStyles = css({
  h2: {
    ...typography.headline3,
    ...margin.top(36),
    ...margin.bottom(16),
  },
  p: {
    ...typography.body,
    ...margin.bottom(16),
  },
  ul: {
    ...typography.body,
    ...margin.bottom(16),
    ...padding.left(24),
  },
  li: {
    ...margin.bottom(8),
  },
  a: {
    color: colors.primary[50],
    transition: 'color 200ms linear',
    ':hover': {
      color: colors.primary[70],
    },
  },
  address: {
    ...typography.body,
    ...margin.bottom(16),
  },
})

const LegalTemplate: React.FunctionComponent<Props> = ({data}) => {
  const post = data.markdownRemark
  invariant(post?.frontmatter, 'Expected to find frontmatter on post')
  invariant(post.html, 'Expected to find html on post')

  return (
    <>
      <SiteMetadata
        title={post.frontmatter.title}
        metaDescription={post.frontmatter.description}
      />
      <Wrapper css={{...padding.y(48), maxWidth: '50rem'}}>
        <Heading>{post.frontmatter.title}</Heading>
        <div
          css={markdownStyles}
          dangerouslySetInnerHTML={{__html: post.html}}
        />
      </Wrapper>
    </>
  )
}

export default LegalTemplate

export const query = graphql`
  query LegalPage($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
