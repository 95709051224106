import React from 'react'
import {Helmet} from 'react-helmet'
import {Maybe} from 'landing-gatsby-graphql-generated'

interface Props {
  title?: Maybe<string>
  metaDescription?: Maybe<string>
}

const SiteMetadata: React.FunctionComponent<Props> = ({
  title,
  metaDescription,
  children,
}) => (
  <Helmet>
    {title && <title>{title}</title>}
    {metaDescription && <meta name="description" content={metaDescription} />}
    {children}
  </Helmet>
)

export default SiteMetadata
